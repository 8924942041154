<template>
  <div>
    <q-form ref="editForm">
      <c-card title="LBLBASEINFO" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable && isOld && !disabled"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="traningEduYear"
              mappingType="PUT"
              label="완료"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
            <c-btn
              v-show="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="traningEduYear"
              :mappingType="mappingType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable && isOld && !disabled"
              label="삭제"
              icon="delete_forever"
              @btnClicked="deleteInfo" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
            <c-datepicker
              required
              :editable="editable"
              :disabled="disabled"
              default="today"
              type="year"
              name="planYear"
              label="년도"
              v-model="traningEduYear.planYear" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
            <c-plant
              required
              :editable="editable"
              :disabled="disabled"
              type="edit"
              name="plantCd"
              v-model="traningEduYear.plantCd" />
          </div>
        </template>
      </c-card>
      <c-card title="대상자" class="cardClassDetailForm q-mt-sm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="row">
              <div class="col-8">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  trueLabel="근무자"
                  falseLabel="근무자"
                  trueValue="Y"
                  falseValue="N"
                  label=""
                  name="workerFlag"
                  v-model="traningEduYear.workerFlag"
                  @datachange="changeAction"
                />
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled||traningEduYear.workerFlag==='N'"
                  type="number"
                  label="인원수"
                  name="workerCount"
                  v-model="traningEduYear.workerCount">
                </c-text>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="row">
              <div class="col-8">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  trueLabel="자위소방대 및 초기대응체계"
                  falseLabel="자위소방대 및 초기대응체계"
                  trueValue="Y"
                  falseValue="N"
                  label=""
                  name="selfFlag"
                  v-model="traningEduYear.selfFlag"
                  @datachange="changeAction"
                />
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled||traningEduYear.selfFlag==='N'"
                  type="number"
                  label="인원수"
                  name="selfCount"
                  v-model="traningEduYear.selfCount">
                </c-text>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="row">
              <div class="col-8">
                <c-checkbox
                  :editable="editable"
                  :disabled="disabled"
                  :isFlag="true"
                  trueLabel="거주자"
                  falseLabel="거주자"
                  trueValue="Y"
                  falseValue="N"
                  label=""
                  name="residentFlag"
                  v-model="traningEduYear.residentFlag"
                  @datachange="changeAction"
                />
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled||traningEduYear.residentFlag==='N'"
                  type="number"
                  label="인원수"
                  name="residentCount"
                  v-model="traningEduYear.residentCount">
                </c-text>
              </div>
            </div>
          </div>
        </template>
      </c-card>
      <c-table
        class="q-mt-sm"
        title="월간일정"
        :columns="gridColumns"
        gridHeight="400px"
        :data="traningEduYear.months"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        hideBottom
        :editable="editable&&!disabled"
        checkDisableColumn="disabledRow"
      >
        <template slot="description-front-tr" slot-scope="props">
          <template v-if="$_.findIndex(gubunIndex, { index: props.rowIndex }) > -1 && traningEduYear.months && traningEduYear.months.length > 0">
            <q-tr :props="props" :key="`e_front_${props.rowIndex}`" class="q-virtual-scroll--with-prev bg-blue-grey fire-fighting-traning-edu-year-gubun-tr">
              <q-td :colspan="13">
                {{$_.find(gubunIndex, { index: props.rowIndex }).sopFireFightingTraningEduYearClassGubunName}}
              </q-td>
            </q-tr>
          </template>
        </template>
      </c-table>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingTraningEduYearDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingTraningEduYearId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      traningEduYear: {
        sopFireFightingTraningEduYearId: '',  // 소방 연간 훈련·교육 계획 일련번호
        plantCd: null,  // 사업장코드
        planYear: '',  // 년도
        sopFireFightingTraningEduYearStepCd: '',  // 진행상태
        workerFlag: 'N',  // 근무자 여부
        workerCount: null,  // 근무자 수(명)
        selfFlag: 'N',  // 자위소방대 및 초기대응체계 여부
        selfCount: null,  // 자위소방대 및 초기대응체계 수(명)
        residentFlag: 'N',  // 거주자 여부
        residentCount: null,  // 거주자 수(명)
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        months: [],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      detailUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingTraningEduYearId)
    },
    disabled() {
      return this.traningEduYear.sopFireFightingTraningEduYearStepCd === 'FFTEY00005'
    },
    gridColumns() {
      let cols = [
        {
          name: 'sopFireFightingTraningEduYearClassName',
          field: 'sopFireFightingTraningEduYearClassName',
          label: '구분',
          align: 'center',
          sortable: true,
          style: 'width: 180px',
        },
      ];
      let monthCols = []
      for (let idx = 1; idx <= 12; idx++) {
        monthCols.push(
          {
            name: `month${idx}Flag`,
            field: `month${idx}Flag`,
            label: `${idx}`,
            align: 'center',
            sortable: true,
            style: 'width: 60px',
            type: 'check',
            'true': 'Y',
            'false': 'N',
          },
        )
      }
      cols.push({
        name: 'month',
        field: 'month',
        label: '연간계획(월)',
        child: monthCols,
      })
      return cols;
    },
    gridHeight() {
      let height = this.contentHeight - 200;
      if (height < 600) {
        height = 600
      }
      return String(height) + 'px'
    },
    gubunIndex() {
      let indexArray = [];
      if (this.traningEduYear.months && this.traningEduYear.months.length > 0) {
        indexArray.push({
          index: 0,
          sopFireFightingTraningEduYearClassGubun: this.traningEduYear.months[0].sopFireFightingTraningEduYearClassGubun,
          sopFireFightingTraningEduYearClassGubunName: this.traningEduYear.months[0].sopFireFightingTraningEduYearClassGubun === 'EDU' ? '교육' : '훈련'
        })
        let length = this.$_.filter(this.traningEduYear.months, { sopFireFightingTraningEduYearClassGubun: this.traningEduYear.months[0].sopFireFightingTraningEduYearClassGubun }).length
        let rejectOthers = this.$_.reject(this.traningEduYear.months, { sopFireFightingTraningEduYearClassGubun: this.traningEduYear.months[0].sopFireFightingTraningEduYearClassGubun })

        indexArray.push({
          index: length,
          sopFireFightingTraningEduYearClassGubun: rejectOthers[0].sopFireFightingTraningEduYearClassGubun,
          sopFireFightingTraningEduYearClassGubunName: rejectOthers[0].sopFireFightingTraningEduYearClassGubun === 'EDU' ? '교육' : '훈련'
        })
      }
      return indexArray;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.fft.fireFightingTraningEduYear.get.url
      this.saveUrl = transactionConfig.fft.fireFightingTraningEduYear.update.url
      this.deleteUrl = transactionConfig.fft.fireFightingTraningEduYear.delete.url
      this.completeUrl = transactionConfig.fft.fireFightingTraningEduYear.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingTraningEduYearId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.traningEduYear, _result.data)
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        /**
         * 월간 데이터 생성
         */
        this.$comm.getComboItems('SOP_FIRE_FIGHTING_TRANING_EDU_YEAR_CLASS_CD').then(_result => {
          this.traningEduYear.months = this.$_.map(_result, item => {
            return this.$_.extend(item, {
              sopFireFightingTraningEduYearMonthId: '',  // 소방 연간 훈련·교육 월간일정 일련번호
              sopFireFightingTraningEduYearId: '',  // 소방 연간 훈련·교육 계획 일련번호
              sopFireFightingTraningEduYearClassCd: item.code,  // 소방 연간 훈련·교육 구분 코드
              sopFireFightingTraningEduYearClassName: item.codeName,  // 소방 연간 훈련·교육 구분 코드
              sopFireFightingTraningEduYearClassGubun: item.attrVal1,  // 소방 연간 훈련·교육 구분 코드
              sopFireFightingTraningEduYearClassFlag: item.attrVal2,  // 소방 연간 훈련·교육 구분 코드
              month1Flag: 'N',  // 1월 여부
              month2Flag: 'N',  // 2월 여부
              month3Flag: 'N',  // 3월 여부
              month4Flag: 'N',  // 4월 여부
              month5Flag: 'N',  // 5월 여부
              month6Flag: 'N',  // 6월 여부
              month7Flag: 'N',  // 7월 여부
              month8Flag: 'N',  // 8월 여부
              month9Flag: 'N',  // 9월 여부
              month10Flag: 'N',  // 10월 여부
              month11Flag: 'N',  // 11월 여부
              month12Flag: 'N',  // 12월 여부
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C',
              disabledRow: true,
            })
          });

        });
      }
    },
    changeAction() {
      if (this.traningEduYear.workerFlag === 'N') {
        this.$set(this.traningEduYear, 'workerCount', null)
      }
      if (this.traningEduYear.selfFlag === 'N') {
        this.$set(this.traningEduYear, 'selfCount', null)
      }
      if (this.traningEduYear.residentFlag === 'N') {
        this.$set(this.traningEduYear, 'residentCount', null)
      }
      if (this.traningEduYear.workerFlag === 'Y' || this.traningEduYear.residentFlag === 'Y') {
        // 근무자, 거주자
        this.$_.forEach(this.traningEduYear.months, month => {
          if (month.sopFireFightingTraningEduYearClassFlag === 'WR') {
            this.$set(month, 'disabledRow', false)
          }
        })
      } else {
        this.$_.forEach(this.traningEduYear.months, month => {
          if (month.sopFireFightingTraningEduYearClassFlag === 'WR') {
            this.$set(month, 'disabledRow', true)
            this.$set(month, 'month1Flag', 'N')
            this.$set(month, 'month2Flag', 'N')
            this.$set(month, 'month3Flag', 'N')
            this.$set(month, 'month4Flag', 'N')
            this.$set(month, 'month5Flag', 'N')
            this.$set(month, 'month6Flag', 'N')
            this.$set(month, 'month7Flag', 'N')
            this.$set(month, 'month8Flag', 'N')
            this.$set(month, 'month9Flag', 'N')
            this.$set(month, 'month10Flag', 'N')
            this.$set(month, 'month11Flag', 'N')
            this.$set(month, 'month12Flag', 'N')
          }
        })
      }
      if (this.traningEduYear.selfFlag === 'Y') {
        // 자위소방대 및 초기대응체계
        this.$_.forEach(this.traningEduYear.months, month => {
          if (month.sopFireFightingTraningEduYearClassFlag === 'S') {
            this.$set(month, 'disabledRow', false)
          }
        })
      } else {
        this.$_.forEach(this.traningEduYear.months, month => {
          if (month.sopFireFightingTraningEduYearClassFlag === 'S') {
            this.$set(month, 'disabledRow', true)
            this.$set(month, 'month1Flag', 'N')
            this.$set(month, 'month2Flag', 'N')
            this.$set(month, 'month3Flag', 'N')
            this.$set(month, 'month4Flag', 'N')
            this.$set(month, 'month5Flag', 'N')
            this.$set(month, 'month6Flag', 'N')
            this.$set(month, 'month7Flag', 'N')
            this.$set(month, 'month8Flag', 'N')
            this.$set(month, 'month9Flag', 'N')
            this.$set(month, 'month10Flag', 'N')
            this.$set(month, 'month11Flag', 'N')
            this.$set(month, 'month12Flag', 'N')
          }
        })
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFightingTraningEduYear.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.fft.fireFightingTraningEduYear.insert.url
        this.mappingType = 'POST'
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEduYear.regUserId = this.$store.getters.user.userId;
              this.traningEduYear.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopFireFightingTraningEduYearId = result.data
      this.getDetail();
    },
    completeInfo() {
      /**
       * 1. 근무자, 자위소방대 및 초기대응체계, 거주자 중 하나 이상 체크
       * 2. 일정 수립
       */
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '완료하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.traningEduYear.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingTraningEduYearId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>

<style lang="sass">
.fire-fighting-traning-edu-year-gubun-tr
  td
    text-align: center
    color: white !important
    font-size: 1rem !important
    font-weight: 600
  .text-align-right
    text-align: right
</style>